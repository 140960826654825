import { PermItem } from '@/types';

export function GetPermissionNode(permRoot: Map<string, any>, accessPath: string[]): PermItem {
  for (const part of accessPath) {
    permRoot = permRoot.get(part);
    if (permRoot === null) {
      break;
    } else if (permRoot === undefined) {
      return permRoot;
    } else {
      permRoot = new Map(Object.entries(permRoot));
    }
  }
  return permRoot;
}
