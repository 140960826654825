import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

function formatDuration(t1: string, t2: string): string {
  const diff = dayjs(t2).diff(dayjs(t1));
  return dayjs.duration(diff).format('s [sec]');
}

function formatTime(t: string, format = 'DD MMM YYYY, h:mm A') {
  return dayjs(t).format(format);
}

function formatDate(time: string) {
  return dayjs(time).format('MMM DD, YYYY');
}

function calculateAbsoluteDaysBetweenDates(start_date: Date, end_date: Date) {
  const numberOfDaysRemaining = Math.ceil(
    Math.abs(end_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24)
  );
  return numberOfDaysRemaining;
}

function calculateDaysBetweenDates(start_date: Date, end_date: Date) {
  const days = (end_date.getTime() - start_date.getTime()) / (1000 * 3600 * 24);
  const numberOfDaysRemaining = days > 0 ? Math.ceil(days) : Math.floor(days);
  return numberOfDaysRemaining;
}

export {
  dayjs,
  formatDuration,
  formatTime,
  formatDate,
  calculateDaysBetweenDates,
  calculateAbsoluteDaysBetweenDates
};
